export function url() {
	if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
		return "http://localhost:3333"
	} else {
		// return "https://walrus-app-n7cim.ondigitalocean.app"
		return "https://api.celebrantbriefcase.com.au"
	}
}

export function pageUrl() {
	if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
		return "http://localhost:3000"
	} else {
		return "https://celebrantbriefcase.com.au"
	}
}

export function stripePublicKey() {
	if (process.env.NODE_ENV === "development") {
		return "pk_test_KbXd81WexDpWwJHqOuqIZqDl00L8C1yxnK"
	} else {
		return "pk_live_roqTianIWvtUiCSZDr4kutNT009bX1OV0r"
	}
}