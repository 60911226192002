import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs))
}

export function dateToNiceString(inputDateObject: Date): string {
	//
	if (inputDateObject) {
		//
		const day = ("0" + inputDateObject.getDate()).slice(-2).toString()
		const month = inputDateObject.toLocaleString('default', { month: 'long' })
		const year = inputDateObject.getFullYear()
		// console.log({ day, month, year })
		//
		return `${day} ${month} ${year}`
		//
	} else {
		return ""
	}
}

function isValidDate(d: Date) {
	return d instanceof Date && !isNaN(d.getTime());
}

export function convertDateFieldToFieldDateFormat(inputDateObject: Date): string {
	//
	if (!inputDateObject) {
		return inputDateObject
	}
	//
	const date = new Date(inputDateObject)
	//
	if (isValidDate(date) === false) {
		//@ts-ignore
		return inputDateObject
	}
	//
	return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2).toString()}-${("0" + date.getDate()).slice(-2).toString()}`
	//
}

export function removeLeadingZero(inputString: string): string {
	//
	if (inputString.startsWith("0")) {
		return inputString.slice(1)
	} else {
		return inputString
	}
	//
}
