import { Fragment, useEffect, useState } from 'react'
import { Combobox, Dialog, Menu, Transition } from '@headlessui/react'
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom'
import { GetSession } from '../../lib/backend/auth'
import { getToken } from '../../lib/token'
import React from 'react'
import { UserShow } from '../../lib/backend/user'
import { ArrowDown, ChevronDown, HelpCircle, Home, Inbox, List, LogOut, LogOutIcon, MailOpen, MenuSquare, Printer, Search, Settings, X, Users } from 'lucide-react'
import { Button } from '../../components/ui/button'
import SideBarLink from '../../components/SideBarLink'
import { User } from '../../../../backend/models/user'
import { Toaster } from "../../components/ui/toaster"

interface Page {
	type: "Normal" | "AFCC" | "Admin",
	name: string,
	href: string,
	icon: any
}

function navigation(): Page[] {
	return [
		{ type: "Normal", name: 'Weddings', href: '/home/weddings', icon: List },
		{ type: "Normal", name: 'Settings', href: '/home/settings', icon: Settings },
		{ type: "Normal", name: 'Form 15 Printers', href: '/home/printers', icon: Printer },
		{ type: "Normal", name: 'Feedback', href: '/home/feedback', icon: Inbox },
		{ type: "Normal", name: 'Support', href: '/home/support', icon: HelpCircle },
		{ type: "AFCC", name: 'AFCC', href: '/home/afcc', icon: Users },
		{ type: "Admin", name: 'All Users', href: '/home/all-users', icon: Users },
		{ type: "Normal", name: 'Logout', href: '/logout', icon: LogOut },
	]
}

function classNames(...classes: any) {
	return classes.filter(Boolean).join(' ')
}

export default function HomePage() {

	const [initialLoading, setInitialLoading] = useState(true)
	const [sidebarOpen, setSidebarOpen] = useState(false)
	const [token, setToken] = useState("")
	const [user, setUser] = useState<{ id: number, manager: User["manager"], status: User["status"] }>({ id: 0, manager: "", status: "A" })

	const navigate = useNavigate();

	const currentPath = useLocation().pathname

	useEffect(() => {
		if (token === "") {
			const token = getToken()
			setToken(token || "")
		}
		pageChange()
	}, [navigate, currentPath])

	async function pageChange() {
		const response = await GetSession()
		if (!response.ok) {
			navigate("/login")
		} else {
			const body = await response.json()
			const meResponse = await UserShow(body.session.id)
			if (meResponse.ok) {
				const meBody = await meResponse.json()
				setUser(meBody)
				setInitialLoading(false)
			} else {
				setInitialLoading(false)
				navigate("/login")
			}
		}
	}

	return (
		<>
			<div className="bg-gray-100 min-h-screen">
				<Transition.Root show={sidebarOpen} as={Fragment}>
					<Dialog as="div" className="relative z-50 xl:hidden" onClose={setSidebarOpen}>
						<Transition.Child
							as={Fragment}
							enter="transition-opacity ease-linear duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="transition-opacity ease-linear duration-300"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="fixed inset-0 bg-gray-900/80" />
						</Transition.Child>

						<div className="fixed inset-0 flex">
							<Transition.Child
								as={Fragment}
								enter="transition ease-in-out duration-300 transform"
								enterFrom="-translate-x-full"
								enterTo="translate-x-0"
								leave="transition ease-in-out duration-300 transform"
								leaveFrom="translate-x-0"
								leaveTo="-translate-x-full"
							>
								<Dialog.Panel className="relative w-full mr-16 flex max-w-xs flex-1">
									<Transition.Child
										as={Fragment}
										enter="ease-in-out duration-300"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in-out duration-300"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<div className="absolute left-full top-0 flex w-16 justify-center pt-5">
											<button id="sidebarButtonMobile" type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
												<span className="sr-only">Close sidebar</span>
												<X className="h-6 w-6 text-white" aria-hidden="true" />
											</button>
										</div>
									</Transition.Child>
									{/* Sidebar component, swap this element with another sidebar if you like */}
									<div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-0">
										<div className="flex h-16 shrink-0 items-center mt-6">
											<img
												className="h-16 w-auto mt-3 mx-auto"
												src="/logo.png"
												alt="Celebrant Briefcase"
											/>
										</div>
										<div
											className='text-white w-full text-center font-bold text-2xl'
										>
											Celebrant Briefcase
										</div>
										<SideBarLinks mobileOrDesktop="Mobile" setSidebarOpen={setSidebarOpen} manager={user.manager} />
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</Dialog>
				</Transition.Root>

				{/* Static sidebar for desktop */}
				<div className="hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:w-72 xl:flex-col">
					{/* Sidebar component, swap this element with another sidebar if you like */}
					<div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-gray-900 px-6 pb-4">
						<div className="flex h-16 shrink-0 items-center mt-6">
							<img
								className="h-16 w-auto mt-3 mx-auto"
								src="/logo.png"
								alt="Celebrant Briefcase"
							/>
						</div>
						<div
							className='text-white w-full text-center font-bold text-2xl'
						>
							Celebrant Briefcase
						</div>
						<SideBarLinks mobileOrDesktop="Desktop" setSidebarOpen={setSidebarOpen} manager={user.manager} />
					</div>
				</div>

				<div className="xl:pl-72">
					<div className="sticky top-0 z-8 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8 xl:hidden">
						<button id="sidebarButtonDesktop" type="button" className="-m-2.5 p-2.5 text-gray-700 xl:hidden" onClick={() => setSidebarOpen(true)}>
							<span className="sr-only">Open sidebar</span>
							{/* <MenuSquare className="h-6 w-6" aria-hidden="true" /> */}
							<svg className="lucide lucide-menu h-6 w-6"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							>
								<line x1="4" x2="20" y1="12" y2="12" />
								<line x1="4" x2="20" y1="6" y2="6" />
								<line x1="4" x2="20" y1="18" y2="18" />
							</svg>
						</button>

						<div className="flex flex-1 gap-x-4 self-stretch">
							<div className="relative z-50 flex flex-1 items-center text-xl font-bold" >
								Celebrant Briefcase
							</div>
							{/* <div className="flex items-center gap-x-4"> */}
							{/* <Link
									to="/logout"
									type="submit"
									className="w-full"
								>
									<Button
										type="button"
										variant={"destructive"}
										className="w-full"
									>
										<LogOutIcon className='mr-2' /> Logout
									</Button>
								</Link> */}
							{/* </div> */}
						</div>

					</div>

					<main className="py-4">
						<div className="px-4 sm:px-6 lg:px-8">
							<Outlet />
							<Toaster />
						</div>
					</main>
				</div>
			</div >
		</>
	)
}

function SideBarLinks({ mobileOrDesktop, setSidebarOpen, manager }: { mobileOrDesktop: "Desktop" | "Mobile", setSidebarOpen: Function, manager: User["manager"] }) {

	return (
		<nav className="flex flex-1 flex-col mt-4">
			<ul className="flex flex-1 flex-col gap-y-7">
				<li>
					<ul className="-mx-2 space-y-1">
						{navigation().map((item) => {
							if (item.type === "Normal" || ((manager === "Admin" || manager === "AFCC") && item.type === "AFCC") || (manager === "Admin" && item.type === "Admin")) {
								return (
									<li key={item.name}>
										<SideBarLink
											id={`${item.name}${mobileOrDesktop}`}
											link={item.href}
											onClick={() => setSidebarOpen(false)}
											icon={item.icon}
											text={item.name}
										/>
									</li>
								)
							}
						})}
					</ul>
				</li>
			</ul>
		</nav>
	)
}

const pages = navigation()

export function PageSelect() {
	const [query, setQuery] = useState('')
	const [selectedPage, setSelectedPage] = useState(null)

	const navigate = useNavigate();

	const filteredPages =
		query === ''
			? pages
			: pages.filter((page) => {
				return page.name.toLowerCase().includes(query.toLowerCase())
			})

	const itemSelected = (event: any) => {
		navigate(event.href)
	}

	return (
		<Combobox as="div" value={selectedPage} onChange={itemSelected} className="w-96 relative z-500">
			<div className="relative mt-2 w-96 z-500">

				<div>
					Celebrant Briefcase
				</div>

				{filteredPages.length > 0 && (
					<Combobox.Options className="relative z-500 mt-1 max-h-40 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm opacity-100">
						{filteredPages.map((page: Page) => (
							<Combobox.Option
								key={page.name}
								value={page}
								className={({ active }) =>
									classNames(
										'relative z-500 cursor-default select-none py-2 pl-3 pr-9 opacity-100',
										active ? 'bg-indigo-600 text-white relative z-50' : 'text-gray-900 relative z-50 opacity-100'
									)
								}
							>
								{({ active, selected }) => (
									<>
										{/* <span className={classNames('block truncate', selected && 'font-semibold')}>{page.name}</span> */}
										{/* {selected && ( */}
										<Link
											to={page.href}
											className={classNames(
												'truncate flex text-left pr-4 relative z-50 opacity-100',
												active ? 'text-white relative z-50' : 'text-black-600 relative z-50 opacity-100'
											)}
										>
											{React.createElement(page.icon, { className: "relative z-50 h-6 w-6 shrink-0 mr-2 opacity-100" })}
											{page.name}
										</Link>
										{/* )} */}
									</>
								)}
							</Combobox.Option>
						))}
					</Combobox.Options>
				)}
			</div>
		</Combobox>
	)
}